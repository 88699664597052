
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    height: auto;
    min-height: calc(100vh - 58px);
    .form {
        box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.1);
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 600px;
        border-radius: 10px;
        min-height: 500px;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 200px;
        position: relative;

        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 500 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: left;
            font-size: 24px !important;
            font-weight: 700 !important;
            margin-top: -20px;
            margin-bottom: 25px !important;
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            color: #000;
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            background-color: $bestpostagemeters-orange !important;
            transition: all 0.2s ease-in-out;
            @include lg {
                max-width: 450px !important;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 450px;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;

                input {
                    &:checked + label {
                        font-weight: 600;
                    }
                }
                label {
                    border-radius: 999px;
                    background-color: $bestpostagemeters-orange;
                    min-height: 50px;
                    margin: 3px;
                    color: #000;
                    display: flex;
                    align-items: center;
                    text-align: left;
                    justify-content: flex-start;
                    @apply shadow-lg;
                    &:hover {
                        @apply shadow;
                        opacity: 0.8;
                    }
                    > span {
                        font-size: 16px !important;
                        margin-top: 0;
                    }
                    > div {
                        margin-right: 15px;
                    }
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
                            rgb(51, 51, 51) 0px 0px 0px 2px;
                        font-weight: 600;
                    }
                    &:hover + label {
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
                            rgb(51, 51, 51) 0px 0px 0px 2px;
                    }
                }
                label {
                    border-radius: 3px;
                    background-color: $bestpostagemeters-orange;

                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    @include md {
                        min-height: 50px;
                    }
                }
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
        @include lg {
            min-width: 400px;
            width: 60%;
            padding: 40px 40px;
            margin-top: 50px;
        }
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: 2;
        background: linear-gradient(
            180deg,
            rgba(36, 157, 216, 0) 0%,
            rgba(36, 157, 216, 0.220588) 100%
        );
    }
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        background-repeat: no-repeat;
        background-image: url("/assets/images/background.svg");
        background-position: bottom center;
        background-size: 300px;
        @include lg {
            background-size: 400px;
            background-position: left 550px;
        }
    }
}
